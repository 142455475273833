@tailwind base;
@tailwind components;
@tailwind utilities;

/*React Date Keeper */
.react-datepicker {
    border: 1px solid rgba(209, 213, 219, 1) !important;
    z-index: 10000 !important;
}
.react-datepicker__header {
    border-bottom: none !important;
    background-color: rgba(243, 244, 246, 1) !important;
}
.react-datepicker-popper {
    z-index: 50 !important;
}
.react-datepicker-popper[data-placement^='bottom']
.react-datepicker__triangle::before {
    border-bottom-color: rgba(209, 213, 219, 1) !important;
}
.react-datepicker-popper[data-placement^='bottom']
.react-datepicker__triangle::after {
    border-bottom-color: rgba(243, 244, 246, 1) !important;
}
.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item {
    height: auto !important;
}
.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #0018a2 !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #eeeeee !important;
}
.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: #f3f4f6 !important;
    color: #000 !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #d1d5db !important;
}

.react-datepicker__navigation {
    top: 4px;
}
